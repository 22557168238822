.Apertura2Notas-parent { 
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: var(--streetSizeH);
}

.primer-bloque {
	position: relative;
}

.segundo-bloque {
	position: relative;
}

@media (max-width: 768px) {
	.Apertura2Notas-parent {
		grid-template-columns: 1fr;
	}

	.primer-bloque {
		padding-bottom: 2.2rem;
	}
}
